import { Theme, createStyles } from '@material-ui/core/styles';
import 'fonts/museoSans.css';


export const styles = (theme: Theme) => createStyles({
    root: {
        margin: 0,
        fontFamily: 'MuseoSans, sans-serif',
        width: '70%',
        marginBottom: '330px',
        marginLeft: '26.5%',
        marginTop: '237px',
        [theme.breakpoints.down(1300)]: {
            width: '75%',
            marginLeft: '22%',
            marginBottom: '300px',
        },
        [theme.breakpoints.down(1000)]: {
            width: '82%',
            marginLeft: '15%',
            marginBottom: '250px',
        },
        [theme.breakpoints.down(900)]: {
            marginBottom: '200px',
            width: '80%',
            marginLeft: '17%',
        },
        [theme.breakpoints.down(850)]: {
            marginBottom: '150px',
        },
        [theme.breakpoints.down(701)]: {
          width: '77%',
          marginLeft: '20%',
        },
        [theme.breakpoints.down(400)]: {
          width: '79%',
          marginLeft: '19%',
        },
        [theme.breakpoints.down(371)]: {
            marginBottom: '100px',
        },
    },
    titleContainer: {
        marginBottom: '133px',
        marginTop: '7%',
        '& #bot': { marginLeft: '59.5px', },
        [theme.breakpoints.down(900)]: {
          marginBottom: '100px',
          marginTop: '0%',
        },
        [theme.breakpoints.down(800)]: {
          '& #bot': { marginLeft: '40px', },
        },
        [theme.breakpoints.down(701)]: {
          '& #bot': { marginLeft: '0px', },
          marginBottom: '80px',
        },
    },
    title: {
        fontSize: '61px',
        color: '#3D4975',
        letterSpacing: '5px',
        fontWeight: 300,
        marginBottom: 0,
        [theme.breakpoints.down(1200)]: {
            fontSize: '58px',
            letterSpacing: '4px',
        },
        [theme.breakpoints.down(900)]: {
            fontSize: '52px',
            letterSpacing: '2.8px',
        },
        [theme.breakpoints.down(800)]: {
          fontSize: '50.5px',
          letterSpacing: '2px',
        },
        [theme.breakpoints.down(701)]: {
          fontSize: '46px',
          letterSpacing: '1px',
        },
        [theme.breakpoints.down(550)]: {
          fontSize: '37px',
          letterSpacing: '.5px',
        },
        [theme.breakpoints.down(450)]: {
          fontSize: '32px',
        },
        [theme.breakpoints.down(371)]: {
          fontSize: '26px',
        },
    },
    section: {
        marginBottom: '100px',
        [theme.breakpoints.down(550)]: {
            marginBottom: '70px',
        },
    },
    subtitleContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    declarationsContainer: {
        marginTop: '120px',
        '& hr': {
            marginBottom: '15px',
            marginTop: '16px',
            background: '#3D4274',
        },
        [theme.breakpoints.down(550)]: {
          marginTop: '90px',
        },
    },
    dottedLine: {
        borderTop: '1px dashed #3D4274',
        background: 'none !important',
    },
    annexContainer: {
        marginTop: '118px',
        marginBottom: '110px',
        [theme.breakpoints.down(100)]: {
            marginTop: '95px',
            marginBottom: '70px',
        },
        [theme.breakpoints.down(900)]: {
            marginTop: '80px',
            marginBottom: '50px',
        },
        [theme.breakpoints.down(371)]: {
            marginTop: '0px',
            marginBottom: '0px',
        },
    },
    annexTableContainer: {
        marginTop: '103px',
    },
    subtitle: {
        fontSize: '24px',
        color: '#3D4274',
        fontWeight: 500,
        letterSpacing: '1px',
        lineHeight: '38px',
        marginBottom: '63px',
        [theme.breakpoints.down(1200)]: {
            fontSize: '21px',
            lineHeight: '35px',
        },
        [theme.breakpoints.down(900)]: {
            fontSize: '18px',
            lineHeight: '32px',
        },
        [theme.breakpoints.down(800)]: {
            fontSize: '16.5px',
            lineHeight: '29.5px',
        },
        [theme.breakpoints.down(701)]: {
          fontSize: '19px',
          marginBottom: '0px',
        },
        [theme.breakpoints.down(550)]: {
          fontSize: '17px',
          lineHeight: '26px',
        },
        [theme.breakpoints.down(450)]: {
          fontSize: '15.3px',
          lineHeight: '24px',
          letterSpacing: '.9px',
        },
        [theme.breakpoints.down(371)]: {
          fontSize: '14px',
          lineHeight: '22px',
        },
    },
    subtitleGrid: {
        fontSize: '32px',
        color: '#9BA0B7',
        letterSpacing: '1px',
        fontWeight: 500,
        lineHeight: '45px',
        marginBottom: '34px',
        [theme.breakpoints.down(1200)]: { fontSize: '29px', },
        [theme.breakpoints.down(900)]: {
            fontSize: '25px',
            lineHeight: '40px',
        },
        [theme.breakpoints.down(800)]: {
            fontSize: '23.5px',
        },
        [theme.breakpoints.down(701)]: {
          fontSize: '27.5px',
          lineHeight: '40px',
        },
        [theme.breakpoints.down(550)]: {
          fontSize: '25px',
          lineHeight: '38px',
        },
        [theme.breakpoints.down(450)]: {
          fontSize: '23px',
        },
        [theme.breakpoints.down(371)]: {
          fontSize: '22px',
          marginBottom: '15px',
        },
    },
    declaration: {
        fontSize: '21px',
        color: '#9BA0B7',
        letterSpacing: '0.57px',
        lineHeight: '26.5px',
        marginTop:'1.5%',
        marginBottom:'2.5rem',
        fontWeight: 500,
        [theme.breakpoints.down(1200)]: { fontSize: '18px', },
        [theme.breakpoints.down(900)]: {
            fontSize: '16.5px',
        },
        [theme.breakpoints.down(800)]: {
            fontSize: '15px',
        },
        [theme.breakpoints.down(701)]: {
          fontSize: '18px',
        },
        [theme.breakpoints.down(550)]: {
          fontSize: '16px',
        },
        [theme.breakpoints.down(450)]: {
          fontSize: '14.5px',
        },
        [theme.breakpoints.down(371)]: {
          fontSize: '13x',
        },
    },
    clause: {
        fontSize: '18.3px',
        color: '#9BA0B7',
        letterSpacing: '1.5px',
        fontWeight: 900,
        [theme.breakpoints.down(1200)]: {
            fontSize: '16.5px',
        },
        [theme.breakpoints.down(900)]: {
            fontSize: '14.5px',
        },
        [theme.breakpoints.down(800)]: {
            fontSize: '13.5px',
        },
        [theme.breakpoints.down(701)]: {
          fontSize: '16px',
        },
        [theme.breakpoints.down(550)]: {
          fontSize: '14px',
        },
        [theme.breakpoints.down(450)]: {
          fontSize: '12.5px',
        },
        [theme.breakpoints.down(371)]: {
          fontSize: '11px',
        },
    },
    clause2: {
        fontSize: '18.3px',
        color: '#9BA0B7',
        letterSpacing: '1.5px',
        fontWeight: 900,
        maxWidth:'80%',
        marginTop:'1em',
        lineHeight:'26px',
        [theme.breakpoints.down(1200)]: {
            fontSize: '16.5px',
            letterSpacing: '1px',
            maxWidth:'75%',
        },
        [theme.breakpoints.down(900)]: {
            fontSize: '14.5px',
            maxWidth:'80%',
            lineHeight:'24px',
        },
        [theme.breakpoints.down(800)]: {
            fontSize: '13.5px',
            lineHeight:'22.5px',
            maxWidth:'85%',
        },
        [theme.breakpoints.down(701)]: {
            fontSize: '16px',
            lineHeight:'24px',
            maxWidth:'85%',
            marginBottom:'3.5em',
            marginTop:'0',
        },
        [theme.breakpoints.down(550)]: {
          fontSize: '14px',
          maxWidth:'95%',
        },
        [theme.breakpoints.down(450)]: {
          fontSize: '12.5px',
          maxWidth:'100%',
          lineHeight:'21px',
        },
        [theme.breakpoints.down(371)]: {
          fontSize: '11px',
          lineHeight:'19px',
        },
    },
    subclause: {
        fontWeight: 700,
        marginBottom: '30px',
    },
    lightClause: {
        fontSize: '18.3px',
        color: '#9BA0B7',
        letterSpacing: '0.57px',
        fontWeight: 500,
        [theme.breakpoints.down(1200)]: { fontSize: '16.5px', },
        [theme.breakpoints.down(900)]: {
            fontSize: '14.5px',
        },
        [theme.breakpoints.down(800)]: {
            fontSize: '13.5px',
        },
        [theme.breakpoints.down(701)]: {
            fontSize: '16px',
        },
        [theme.breakpoints.down(550)]: {
          fontSize: '14px',
        },
        [theme.breakpoints.down(550)]: {
          fontSize: '12.5px',
        },
        [theme.breakpoints.down(371)]: {
          fontSize: '11px',
        },
    },
    subGridCommissions: {
        marginBottom: '100px',
        marginTop: '48px',
        [theme.breakpoints.down(850)]: {
          marginBottom: '80px',
        },
        [theme.breakpoints.down(371)]: {
          marginBottom: '40px',
        },
    },
    commissionTittle: {
        fontSize: '15px',
        color: '#3D4274',
        fontWeight: 900,
        marginBottom: '15px',
        marginTop:'1em',
        [theme.breakpoints.down(1200)]: { fontSize: '13.5px'},
        [theme.breakpoints.down(900)]: {fontSize: '12px',marginTop:'1.8em',},
        [theme.breakpoints.down(800)]: {fontSize: '11.5px'},
        [theme.breakpoints.down(701)]: {fontSize: '13px'},
        [theme.breakpoints.down(550)]: {fontSize: '12px'},
        [theme.breakpoints.down(450)]: {fontSize: '11px'},
    },
    commission: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        marginBottom: '10px',
        '& .commissionText': {
            fontSize: '18.33px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.55px',
            marginBottom: '0',
            whiteSpace: 'nowrap',
            textOverflow: 'clip',
        },
        '& .blueSmallText': {
            fontSize: '29.9px',
            fontWeight: 700,
            color: '#3D4274',
            marginBottom: '0',
            marginRight: '10px',
            marginLeft: '10px',
        },
        '& .blueText': {
            fontSize: '45.01px',
            fontWeight: 900,
            color: '#3D4274',
            letterSpacing: '0.03px',
            marginBottom: '0',
            marginRight: '10px',
            marginLeft: '10px',
        },
        [theme.breakpoints.down(1200)]: {
            '& .commissionText': { fontSize: '16.5px', },
            '& .blueSmallText': {fontSize: '26px'},
            '& .blueText': {fontSize: '42px'},
        },
        [theme.breakpoints.down(900)]: {
          '& .commissionText': { fontSize: '14.5px', },
          '& .blueSmallText': {fontSize: '24px'},
          '& .blueText': {fontSize: '40px'},
        },
        [theme.breakpoints.down(800)]: {
          '& .commissionText': { fontSize: '12px', },
          '& .blueSmallText': {fontSize: '20px'},
          '& .blueText': {fontSize: '35px'},
        },
        [theme.breakpoints.down(750)]: {
          '& .commissionText': { fontSize: '11px', },
          '& .blueSmallText': {fontSize: '19px'},
          '& .blueText': {fontSize: '34px'},
        },
        [theme.breakpoints.down(701)]: {
          '& .commissionText': { fontSize: '14.5px', },
          '& .blueSmallText': {fontSize: '26px'},
          '& .blueText': {fontSize: '42px'},
        },
        [theme.breakpoints.down(650)]: {
          '& .commissionText': { fontSize: '13px', },
          '& .blueSmallText': {fontSize: '25px'},
          '& .blueText': {fontSize: '40px'},
        },
        [theme.breakpoints.down(550)]: {
          '& .commissionText': { fontSize: '12.5px', },
          '& .blueSmallText': {fontSize: '23.5px'},
          '& .blueText': {fontSize: '35px'},
        },
        [theme.breakpoints.down(450)]: {
          '& .commissionText': { fontSize: '10px', },
          '& .blueSmallText': {fontSize: '22px'},
          '& .blueText': {fontSize: '32px'},
        },
        [theme.breakpoints.down(371)]: {
          '& .commissionText': { fontSize: '9px', },
          '& .blueSmallText': {fontSize: '20px'},
          '& .blueText': {fontSize: '30px'},
        },
    },
    bottomText: {
        fontSize: '18.33px',
        fontWeight: 500,
        color: '#3D4274',
        letterSpacing: '0.75px',
        lineHeight: '26.35px',
        marginTop: '15px',
        marginLeft: '15px',
        marginBottom: '40px',
        width: '94%',
        '& .boldText': { fontWeight: 900 },
        [theme.breakpoints.down(1200)]: {fontSize: '16.5px'},
        [theme.breakpoints.down(900)]: {fontSize: '14.5px',lineHeight: '23.5px',},
        [theme.breakpoints.down(800)]: {fontSize: '12px',lineHeight: '19px',},
        [theme.breakpoints.down(750)]: {
          fontSize: '11px',lineHeight: '17px',
        },
        [theme.breakpoints.down(701)]: {fontSize: '14.5px',lineHeight: '24px',},
        [theme.breakpoints.down(650)]: {fontSize: '13px',lineHeight: '22px',},
        [theme.breakpoints.down(550)]: {fontSize: '12px',lineHeight: '19px',},
        [theme.breakpoints.down(450)]: {fontSize: '10px',lineHeight: '16px',},
        [theme.breakpoints.down(371)]: {fontSize: '8.5px',lineHeight: '14px',},
  },
    rightNumber: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    subGridSignatures: {
        marginTop: '61.85px',
        justifyContent: 'space-between',
        '& .under': {
            border: 0,
            borderBottom: '1px solid #3D4274',
        },
        '& .signature': {
            fontSize: '18.47px',
            fontWeight: 700,
            color: '#404875',
            letterSpacing: '0.06px',
            width: '266.17px',
        },
        '& .signatureTextborderTop': {
            fontSize: '18.47px',
            fontWeight: 700,
            color: '#404875',
            letterSpacing: '0.06px',
            borderTop: '1px solid #3D4274',
            width: '266.17px',
        },
        '& .signatureTextMiddle': {
            fontSize: '18.47px',
            fontWeight: 700,
            color: '#404875',
            letterSpacing: '0.06px',
            paddingTop: '17px',
            paddingBottom: '56.28px',
            borderBottom: '1px solid #3D4274',
            width: '266.17px',
        },
        '& .signatureTextBottom': {
            fontSize: '18.47px',
            fontWeight: 700,
            color: '#404875',
            letterSpacing: '0.06px',
            borderBottom: '1px solid #3D4274',
            width: '266.17px',
            paddingBottom: '56.28px',
        },
        [theme.breakpoints.down(1200)]: {
          '& .signature': {
              fontSize: '16.5px',
              width: '230px',
          },
          '& .signatureTextborderTop': {
              fontSize: '16.5px',
              width: '230px',
          },
          '& .signatureTextMiddle': {
              fontSize: '16.5px',
              width: '230px',
          },
          '& .signatureTextBottom': {
              fontSize: '16.5px',
              width: '230px',
          },
        },
        [theme.breakpoints.down(1000)]: {
          '& .signature': {
              width: '200px',
          },
          '& .signatureTextborderTop': {
              width: '200px',
          },
          '& .signatureTextMiddle': {
                width: '200px',
          },
          '& .signatureTextBottom': {
              width: '200px',
          },
        },
        [theme.breakpoints.down(900)]: {
          '& .signature': {
              fontSize: '14.5px',
              width: '180px',
          },
          '& .signatureTextborderTop': {
              fontSize: '14.5px',
              width: '180px',
          },
          '& .signatureTextMiddle': {
              fontSize: '14.5px',
              width: '180px',
          },
          '& .signatureTextBottom': {
              fontSize: '14.5px',
              width: '180px',
          },
        },
        [theme.breakpoints.down(800)]: {
          '& .signature': {
              fontSize: '13.5px',
          },
          '& .signatureTextborderTop': {
              fontSize: '13.5px',
          },
          '& .signatureTextMiddle': {
              fontSize: '13.5px',
          },
          '& .signatureTextBottom': {
              fontSize: '13.5px',
          },
        },
        [theme.breakpoints.down(701)]: {
          '& .signature': {
              fontSize: '16px',
          },
          '& .signatureTextborderTop': {
              fontSize: '16px',
          },
          '& .signatureTextMiddle': {
              fontSize: '16px',
          },
          '& .signatureTextBottom': {
              fontSize: '16px',
          },
        },
        [theme.breakpoints.down(550)]: {
          '& .signature': {
              fontSize: '14px',
              width: '130px',
          },
          '& .signatureTextborderTop': {
              fontSize: '14px',
              width: '130px',
          },
          '& .signatureTextMiddle': {
              fontSize: '14px',
              width: '130px',
          },
          '& .signatureTextBottom': {
              fontSize: '14px',
              width: '130px',
          },
        },
        [theme.breakpoints.down(450)]: {
          '& .signature': {
              fontSize: '12.5px',
              width: '120px',
          },
          '& .signatureTextborderTop': {
              fontSize: '12.5px',
              width: '120px',
          },
          '& .signatureTextMiddle': {
              fontSize: '12.5px',
              width: '120px',
          },
          '& .signatureTextBottom': {
              fontSize: '12.5px',
              width: '120px',
          },
        },
        [theme.breakpoints.down(371)]: {
          '& .signature': {
              fontSize: '11px',
              width: '100px',
          },
          '& .signatureTextborderTop': {
              fontSize: '11px',
              width: '100px',
          },
          '& .signatureTextMiddle': {
              fontSize: '11px',
              width: '100px',
          },
          '& .signatureTextBottom': {
              fontSize: '11px',
              width: '100px',
          },
        },
    },
    signatureStrong: {
        fontSize: '16.63px',
        color: '#404875',
        letterSpacing: '0.06px',
        fontWeight: 900,
        [theme.breakpoints.down(1200)]: { fontSize: '14px', },
        [theme.breakpoints.down(900)]: {fontSize: '12px',},
        [theme.breakpoints.down(800)]: {fontSize: '11.2px'},
        [theme.breakpoints.down(701)]: {fontSize: '14px'},
        [theme.breakpoints.down(550)]: {fontSize: '12px'},
        [theme.breakpoints.down(450)]: {fontSize: '11.2px'},
        [theme.breakpoints.down(371)]: {fontSize: '10px'},
    },
    tableTittle: {
        fontSize: '18.3px',
        color: '#404875',
        letterSpacing: '1.7px',
        lineHeight: '21px',
        fontWeight: 900,
        fontFamily: 'MuseoSans, sans-serif',
        [theme.breakpoints.down(1200)]: { fontSize: '16.5px', },
        [theme.breakpoints.down(1100)]: { fontSize: '14px', letterSpacing: '1px',  lineHeight: '19px',},
        [theme.breakpoints.down(900)]: {
            fontSize: '12px',
            lineHeight: '0px',
            letterSpacing: '.6px',
        },
        [theme.breakpoints.down(800)]: {fontSize: '10.5px',letterSpacing: '.4px',},
        [theme.breakpoints.down(701)]: {
          fontSize: '16px',
          letterSpacing: '1px',
          marginRight:'50px'
        },
        [theme.breakpoints.down(650)]: {
          marginRight:'60px'
        },
        [theme.breakpoints.down(550)]: {
          fontSize: '14px',
          marginRight:'30px'
        },
        [theme.breakpoints.down(450)]: {
          fontSize: '12.5px',
          marginRight:'25px'
        },
        [theme.breakpoints.down(371)]: {
          fontSize: '11px',
        },
  },
    tableText: {
        fontSize: '18.3px',
        color: '#404875',
        letterSpacing: '0.5px',
        fontWeight: 500,
        lineHeight: '26.35px',
        textAlign: 'left',
        verticalAlign: 'top',
        fontFamily: 'MuseoSans, sans-serif',
        '& .bold': {
            fontWeight: 700,
        },
        '& .bold2': {
            fontWeight: 700,
        },
        [theme.breakpoints.down(1200)]: { fontSize: '16.5px',lineHeight: '24px',},
        [theme.breakpoints.down(1000)]: { lineHeight: '22.5px',},
        [theme.breakpoints.down(900)]: {fontSize: '14.5px',lineHeight: '20px',},
        [theme.breakpoints.down(800)]: {fontSize: '12px',lineHeight: '10px',},
        [theme.breakpoints.down(701)]: {fontSize: '16px',lineHeight: '24px',},
        [theme.breakpoints.down(650)]: {
          '& .bold2': {marginRight:'50px'},
        },
        [theme.breakpoints.down(550)]: {
          fontSize: '14px',lineHeight: '20px',
          '& .bold2': {marginRight:'40px'},
        },
        [theme.breakpoints.down(450)]: {
          fontSize: '12.5px',
          '& .bold2': {marginRight:'10px'},
      },
      [theme.breakpoints.down(371)]: {
        fontSize: '11px',
    },

    },
    borderBold: {
        borderBottom: '2.3px solid #3D4274',
        [theme.breakpoints.down(1250)]: { padding:'10px',},
        [theme.breakpoints.down(701)]: { padding:'19px',},
        [theme.breakpoints.down(550)]: { padding:'15px',},
        [theme.breakpoints.down(371)]: { padding:'10px',},
    },
    borderLight: {
        borderBottom: '0.9px solid #3D4274',
    },
    borderLess: {
        borderBottom: '0px solid #3D4274',
    },
    borderLeftBottom: {
        padding:'19px',
        borderRight: '0.9px solid #3D4274',
        borderBottom: '0.9px solid #3D4274',
        [theme.breakpoints.down(1250)]: { padding:'17px',},
          [theme.breakpoints.down(1050)]: { padding:'10px',},
          [theme.breakpoints.down(701)]: { padding:'19px',},
          [theme.breakpoints.down(650)]: { padding:'15px',},
          [theme.breakpoints.down(371)]: { padding:'10px',},
    },
    borderLeftBottomVerticalAlign: {
        verticalAlign: 'top',
        borderRight: '0px solid #3D4274',
        borderBottom: '0.9px solid #3D4274',
    },
    borderLessBottom: {
        borderRight: '0px solid #3D4274',
        borderBottom: '0.9px solid #3D4274',
    },
    borderLeftBottomBold: {
        borderRight: '0.9px solid #3D4274',
        borderBottom: '2.3px solid #3D4274',
    },
    borderLessLastChild: {
        borderRight: '0px solid #3D4274',
        borderBottom: '0.9px solid #3D4274',
        width: '30%',
    },
    text: {
        fontSize: '18.33px',
        color: '#3D4274',
        letterSpacing: '0.75px',
        lineHeight: '26.35px',
        fontWeight: 500,
        margin: '1em 0 0 0',
        '& .signature': {
            textJustify: 'right',
            textAlign: 'right',
            float: 'right',
            letterSpacing: 0,
        },
        '& .under': {
            border: 0,
            borderBottom: '1px solid #3D4274',
        },
        [theme.breakpoints.down(1200)]: {fontSize: '16.5px'},
        [theme.breakpoints.down(900)]: {fontSize: '14.5px',lineHeight: '24px',},
        [theme.breakpoints.down(800)]: {fontSize: '13.5px',lineHeight: '23px',},
        [theme.breakpoints.down(701)]: {fontSize: '16px',lineHeight: '24px'},
        [theme.breakpoints.down(550)]: {fontSize: '14px',lineHeight: '22px'},
        [theme.breakpoints.down(450)]: {fontSize: '12.5px',lineHeight: '20px'},
        [theme.breakpoints.down(371)]: {fontSize: '11px',lineHeight: '18px'},
    },
    underSign: {
        border: 0,
        marginLeft: '45%',
        borderTop: '1px solid #3D4274',
        height: '8px',

    },
    strong: { fontWeight: 900 },
    straightLine: {
        borderTop: '1px solid #3D4274',
        background: 'none !important',
    },
    dottedLine: {
        borderTop: '1px dashed #3D4274',
        background: 'none !important',
        margin: '1em 0 0 0',
    },
});
