// React imports
import React from 'react';

// gatbsy imports
import { PageProps, graphql } from 'gatsby';

// SEO
import SEO from 'components/seo';

// Theme and layout
import WithTheme from 'components/WithTheme';
import Layout from 'views/Layout'

// Page views
import CreditContract from  'views/CreditContract'

type DataProps = {
    site: {
        buildTime: string;
    }
};

const ContratoCreditoPage = () => (
    <WithTheme>
        <Layout>
        <SEO title='Contrato de apertura de credito' />

        <CreditContract />
        </Layout>
    </WithTheme>
);

export default ContratoCreditoPage;

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`;
